import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import fbRecommendations from "./fb-recommendations.svg"
import Stars from "./Stars"
import PropTypes from "prop-types"
var classNames = require("classnames")

export default function FeaturedSocial(props) {
  // TO-DO: Match else format
  if (props.inlineStars) {
    return (
      <div className="featured-social inline-stars">
        <div>
          <a
            className="link-wrapper"
            href={props.social.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>{props.heading}</h3>
            <div className="review-icons">
              <Stars rating={props.social.rating} />
            </div>
          </a>
          <a
            className="link-wrapper"
            href={props.social.url}
            target="_blank"
            rel="noopener noreferrer"
          ></a>
        </div>
        <a
          href={props.social.url}
          className="reviews-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="reviews-number">{props.social.totalReviews}</span>{" "}
          <span className="reviews-word">
            {props.language === "es" ? "Reseñas" : props.reviewNoun}
          </span>
        </a>
      </div>
    )
  } else {
    return (
      <div className="featured-social">
        <SocialHeading
          locationName={props.locationName}
          heading={props.heading}
          totalReviews={props.social.totalReviews}
          url={props.social.url}
        />
        <SocialStarsRating
          url={props.social.url}
          totalReviews={props.social.totalReviews}
          rating={props.social.rating}
        />
      </div>
    )
  }
}

function SocialHeading(props) {
  return (
    <div>
      {props.locationName && (
        <span className="reviews-city">{props.locationName} Office</span>
      )}
      {props.heading && (
        <a
          className="link-wrapper"
          href={props.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <h3>{props.heading}</h3>
          <div className="total-reviews">({props.totalReviews})</div>
        </a>
      )}
    </div>
  )
}

function SocialStarsRating(props) {
  return (
    <div>
      <a
        href={props.url}
        className="reviews-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="review-icons">
          <Stars rating={props.rating} />
        </div>
      </a>
      <a
        href={props.url}
        className="reviews-link reviews-link__rating"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="reviews-number">{props.rating.toFixed(1)}</span>
      </a>
    </div>
  )
}

{
  /* <div class="featured-social">
  <div>
    <span class="reviews-city" style="font-size: 0.875rem; line-height: 1.71; color: rgb(109, 110, 111); display: block; margin-bottom: -4px; font-weight: bold; font-family: Montserrat;">Fresno Office</span>
    <a class="link-wrapper" href="https://www.google.com/maps/search/?api=1&amp;query=Google&amp;query_place_id=ChIJobaQLZFdlIARE8JAE7-aaeE" target="_blank" rel="noopener noreferrer">
      <h3>Google</h3>
      <div class="total-reviews">(613)</div>
    </a>
  </div>
  <div>
    <a href="https://www.google.com/maps/search/?api=1&amp;query=Google&amp;query_place_id=ChIJobaQLZFdlIARE8JAE7-aaeE" class="reviews-link" target="_blank">
      <div class="review-icons">
        <div class="svg-wrapper google-stars"></div>
      </div>
    </a>
    <a href="https://www.google.com/maps/search/?api=1&amp;query=Google&amp;query_place_id=ChIJobaQLZFdlIARE8JAE7-aaeE" class="reviews-link" target="_blank"><span class="reviews-number">5.0</span></a>
  </div>
</div> */
}
