import { useStaticQuery, graphql } from "gatsby"

export const useGoogleReviewsData = () => {
  const locationReviewData = useStaticQuery(
    graphql`
      query googleReviewsData {
        allSocialJson(
          filter: { data: { platform: { eq: "googleMyBusiness" } } }
        ) {
          nodes {
            data {
              rating
              totalReviews
              code
              platform
              platformId
              url
            }
            person
            location
          }
        }
      }
    `
  )

  let locationNodes = locationReviewData.allSocialJson.nodes.filter(
    nodes => nodes.location != null && nodes.person === null
  )
  let peopleNodes = locationReviewData.allSocialJson.nodes.filter(
    nodes => nodes.data.location == null && nodes.person != null
  )

  let data = {
    locationNodes: locationNodes,
    peopleNodes: peopleNodes
  }

  return data
}
