import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"

import fbRecommendations from "./fb-recommendations.svg"
import Stars from "./Stars"

import PropTypes from "prop-types"
import FeaturedSocial from "./FeaturedSocial"

var classNames = require("classnames")

import { useGoogleReviewsData } from "../../hooks/use-google-reviews-data"
import { useFacebookReviewsData } from "../../hooks/use-facebook-reviews-data"
import { Icon } from "@blueprintjs/core"

export default function SocialReviews(props) {
  const { locationNodes } = useGoogleReviewsData()
  const facebookNodes = useFacebookReviewsData().locationNodes

  let featuredSocialClasses = classNames({
    "featured-social": true,
    headless: props.googleHeading
  })

  const animate = props.animation ? "fade-up" : ""

  const wrapperClasses = classNames({
    "home-section": props.heading,
    "ratings-review top-down reviews-width home-reviews": true,
    "color-back": props.colorBack,
    "big-rating": true
  })

  const reviewsContainer = classNames({
    "all-reviews-container-flex": props.isColumn,
    "all-reviews-container-grid": !props.isColumn
  })

  if (props.iconless) {
    return <Iconless {...props} />
  } else if (props.useFirstLocation) {
    let review
    // Find first location with review
    for (let i = 0; i < locationNodes.length; i++) {
      console.log(locationNodes[i])
      if (
        locationNodes[i].data.rating &&
        locationNodes[i].data.totalReviews > 0 &&
        locationNodes[i].person === null
      ) {
        review = locationNodes[i].data
        break
      }
    }

    return (
      <div className="featured-social headless">
        <a
          className="link-wrapper"
          href={review.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="review-icons">
            <h4 className="google-rating">{review.rating.toFixed(1)}</h4>
            <Stars rating={review.rating} />
          </div>
        </a>
        <a className="review-number-text" href={review.url} target="_blank">
          {review.totalReviews} Reviews
        </a>
      </div>
    )
  }
  return (
    <>
      {props.google && (
        <div className="social-reviews">
          <div
            className={featuredSocialClasses}
            style={{ paddingBottom: props.paddingBottom }}
          >
            {props.googleHeading && (
              <a
                className="link-wrapper"
                href={google.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3>Google</h3>
              </a>
            )}
            <a
              className="link-wrapper"
              href={google.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="review-icons">
                <h4 className="google-rating">{ratingTwoDigits}</h4>
                <Stars rating={google.rating} />
              </div>
            </a>
            <a className="review-number-text" href={google.url} target="_blank">
              {google.totalReviews}{" "}
              {props.language === "es" ? "Reseñas" : "Reviews"}
            </a>
          </div>
        </div>
      )}

      {props.facebook && (
        <div className="social-reviews">
          <div className="featured-social">
            <a
              className="link-wrapper"
              href={facebook.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h3>Facebook</h3>
            </a>
            <a
              className="link-wrapper"
              href={facebook.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="review-icons">
                <div className="svg-wrapper">
                  <img src={fbRecommendations} alt="Facebook Reviews" />
                </div>
              </div>
            </a>
            <a
              className="review-number-text"
              href={facebook.url}
              target="_blank"
            >
              {facebook.totalReviews}{" "}
              {props.language === "es" ? "Reseñas" : "Reviews"}
            </a>
          </div>
        </div>
      )}
    </>
  )

  function Iconless(props) {
    return (
      <div className={wrapperClasses}>
        <div className="column">
          <div
            className={reviewsContainer}
            style={{ flexDirection: props.isColumn ? "column" : "row" }}
          >
            {locationNodes.map(location => {
              if (location.data.totalReviews > 0 && location.data.rating) {
                return (
                  <FeaturedSocial
                    {...props}
                    key={location.data.url}
                    social={location.data}
                    heading="Google"
                    reviewNoun="Reviews"
                    locationName={location.location}
                  />
                )
              }
            })}
            {facebookNodes.map(location => {
              if (location.data.totalReviews > 0 && location.data.rating) {
                return (
                  <FeaturedSocial
                    {...props}
                    key={location.data.url}
                    social={location.data}
                    heading="Facebook"
                    reviewNoun="Recommendations"
                    // locationName={location.location}
                  />
                )
              }
            })}
          </div>
        </div>
      </div>
    )
  }
}
